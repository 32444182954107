import { Button, Space, Table, TableProps, Tag } from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import { useState } from "react";

interface DataType {
  key: string;
  domain: string;
  vt_dc_mb: boolean | null;
  vnpt_dc_mb: boolean | null;
  fpt_dc_mb: boolean | null;
  vt_dc_mt: boolean | null;
  vnpt_dc_mt: boolean | null;
  fpt_dc_mt: boolean | null;
  vt_dc_mn: boolean | null;
  vnpt_dc_mn: boolean | null;
  fpt_dc_mn: boolean | null;
  vt_4g_mb: boolean | null;
  vina_4g_mb: boolean | null;
  mobi_4g_mb: boolean | null;
  vt_4g_mt: boolean | null;
  vina_4g_mt: boolean | null;
  mobi_4g_mt: boolean | null;
  vt_4g_mn: boolean | null;
  vina_4g_mn: boolean | null;
  mobi_4g_mn: boolean | null;
}

const Checkblock = () => {
  const [value, setValue] = useState<string>('');
  const [data, setData] = useState<DataType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Domain',
      dataIndex: 'domain',
      key: 'domain',
      width: 300,
      fixed: 'left',
    },
    {
      title: 'VT DC MB',
      dataIndex: 'vt_dc_mb',
      key: 'vt_dc_mb',
      width: 150,
      render: (value) => value == null ? '' : value ? <Tag color="green">OK</Tag> : <Tag color="red">Block</Tag>,
    },
    {
      title: 'VNPT DC MB',
      dataIndex: 'vnpt_dc_mb',
      key: 'vnpt_dc_mb',
      width: 150,
      render: (value) => value == null ? '' : value ? <Tag color="green">OK</Tag> : <Tag color="red">Block</Tag>,
    },
    {
      title: 'FPT DC MB',
      dataIndex: 'fpt_dc_mb',
      key: 'fpt_dc_mb',
      width: 150,
      render: (value) => value == null ? '' : value ? <Tag color="green">OK</Tag> : <Tag color="red">Block</Tag>,
    },
    {
      title: 'VT DC MT',
      dataIndex: 'vt_dc_mt',
      key: 'vt_dc_mt',
      width: 150,
      render: (value) => value == null ? '' : value ? <Tag color="green">OK</Tag> : <Tag color="red">Block</Tag>,
    },
    {
      title: 'VNPT DC MT',
      dataIndex: 'vnpt_dc_mt',
      key: 'vnpt_dc_mt',
      width: 150,
      render: (value) => value == null ? '' : value ? <Tag color="green">OK</Tag> : <Tag color="red">Block</Tag>,
    },
    {
      title: 'FPT DC MT',
      dataIndex: 'fpt_dc_mt',
      key: 'fpt_dc_mt',
      width: 150,
      render: (value) => value == null ? '' : value ? <Tag color="green">OK</Tag> : <Tag color="red">Block</Tag>,
    },
    {
      title: 'VT DC MN',
      dataIndex: 'vt_dc_mn',
      key: 'vt_dc_mn',
      width: 150,
      render: (value) => value == null ? '' : value ? <Tag color="green">OK</Tag> : <Tag color="red">Block</Tag>,
    },
    {
      title: 'VNPT DC MN',
      dataIndex: 'vnpt_dc_mn',
      key: 'vnpt_dc_mn',
      width: 150,
      render: (value) => value == null ? '' : value ? <Tag color="green">OK</Tag> : <Tag color="red">Block</Tag>,
    },
    {
      title: 'FPT DC MN',
      dataIndex: 'fpt_dc_mn',
      key: 'fpt_dc_mn',
      width: 150,
      render: (value) => value == null ? '' : value ? <Tag color="green">OK</Tag> : <Tag color="red">Block</Tag>,
    },
    // 4G
    {
      title: 'VT 4G MB',
      dataIndex: 'vt_4g_mb',
      key: 'vt_4g_mb',
      width: 150,
      render: (value) => value == null ? '' : value ? <Tag color="green">OK</Tag> : <Tag color="red">Block</Tag>,
    },
    {
      title: 'VINA 4G MB',
      dataIndex: 'vina_4g_mb',
      key: 'vina_4g_mb',
      width: 150,
      render: (value) => value == null ? '' : value ? <Tag color="green">OK</Tag> : <Tag color="red">Block</Tag>,
    },
    {
      title: 'MOBI 4G MB',
      dataIndex: 'mobi_4g_mb',
      key: 'mobi_4g_mb',
      width: 150,
      render: (value) => value == null ? '' : value ? <Tag color="green">OK</Tag> : <Tag color="red">Block</Tag>,
    },
    {
      title: 'VT 4G MT',
      dataIndex: 'vt_4g_mt',
      key: 'vt_4g_mt',
      width: 150,
      render: (value) => value == null ? '' : value ? <Tag color="green">OK</Tag> : <Tag color="red">Block</Tag>,
    },
    {
      title: 'VINA 4G MT',
      dataIndex: 'vina_4g_mt',
      key: 'vina_4g_mt',
      width: 150,
      render: (value) => value == null ? '' : value ? <Tag color="green">OK</Tag> : <Tag color="red">Block</Tag>,
    },
    {
      title: 'MOBI 4G MT',
      dataIndex: 'mobi_4g_mt',
      key: 'mobi_4g_mt',
      width: 150,
      render: (value) => value == null ? '' : value ? <Tag color="green">OK</Tag> : <Tag color="red">Block</Tag>,
    },
    {
      title: 'VT 4G MN',
      dataIndex: 'vt_4g_mn',
      key: 'vt_4g_mn',
      width: 150,
      render: (value) => value == null ? '' : value ? <Tag color="green">OK</Tag> : <Tag color="red">Block</Tag>,
    },
    {
      title: 'VINA 4G MN',
      dataIndex: 'vina_4g_mn',
      key: 'vina_4g_mn',
      width: 150,
      render: (value) => value == null ? '' : value ? <Tag color="green">OK</Tag> : <Tag color="red">Block</Tag>,
    },
    {
      title: 'MOBI 4G MN',
      dataIndex: 'mobi_4g_mn',
      key: 'mobi_4g_mn',
      width: 150,
      render: (value) => value == null ? '' : value ? <Tag color="green">OK</Tag> : <Tag color="red">Block</Tag>,
    },
  ];

  const handleClickCheckDomain = async () => {
    const domains = value.split('\n').map((domain) => domain.trim());

    if (!domains.length) {
      return;
    }

    let newData: DataType[] = domains.map((domain) => ({
      key: domain,
      domain: domain,
      vt_dc_mb: null,
      vnpt_dc_mb: null,
      fpt_dc_mb: null,
      vt_dc_mt: null,
      vnpt_dc_mt: null,
      fpt_dc_mt: null,
      vt_dc_mn: null,
      vnpt_dc_mn: null,
      fpt_dc_mn: null,
      vt_4g_mb: null,
      vina_4g_mb: null,
      mobi_4g_mb: null,
      vt_4g_mt: null,
      vina_4g_mt: null,
      mobi_4g_mt: null,
      vt_4g_mn: null,
      vina_4g_mn: null,
      mobi_4g_mn: null,
    }));
    setData(newData);
    setLoading(true);
    for (let i = 0; i < domains.length; i++) {
      const domain = domains[i];

      const [vt_dc_mb, vnpt_dc_mb, fpt_dc_mb, vt_4g_mb, vina_4g_mb, mobi_4g_mb] = await Promise.all([
        checkDomainVT(domain),
        checkDomainVNPT(domain),
        checkDomainFPT(domain),
        checkDomainVT4G(domain),
        checkDomainVINA4G(domain),
        checkDomainMOBI4G(domain),
      ]);

      const vt_dc_mt = vt_dc_mb;
      const vnpt_dc_mt = vnpt_dc_mb;
      const fpt_dc_mt = fpt_dc_mb;
      const vt_dc_mn = vt_dc_mb;
      const vnpt_dc_mn = vnpt_dc_mb;
      const fpt_dc_mn = fpt_dc_mb;
      // 4G

      const vt_4g_mt = vt_4g_mb;
      const vina_4g_mt = vina_4g_mb;
      const mobi_4g_mt = mobi_4g_mb;
      const vt_4g_mn = vt_4g_mb;
      const vina_4g_mn = vina_4g_mb;
      const mobi_4g_mn = mobi_4g_mb;

      newData[i] = {
        key: domain,
        domain: domain,
        vt_dc_mb: vt_dc_mb,
        vnpt_dc_mb: vnpt_dc_mb,
        fpt_dc_mb: fpt_dc_mb,
        vt_dc_mt: vt_dc_mt,
        vnpt_dc_mt: vnpt_dc_mt,
        fpt_dc_mt: fpt_dc_mt,
        vt_dc_mn: vt_dc_mn,
        vnpt_dc_mn: vnpt_dc_mn,
        fpt_dc_mn: fpt_dc_mn,
        vt_4g_mb: vt_4g_mb,
        vina_4g_mb: vina_4g_mb,
        mobi_4g_mb: mobi_4g_mb,
        vt_4g_mt: vt_4g_mt,
        vina_4g_mt: vina_4g_mt,
        mobi_4g_mt: mobi_4g_mt,
        vt_4g_mn: vt_4g_mn,
        vina_4g_mn: vina_4g_mn,
        mobi_4g_mn: mobi_4g_mn,
      };

      setData([...newData]);

      if (i === domains.length - 1) {
        setLoading(false);
      }
    }
  }

  const checkDomainVT = async (domain: string) => {
    try {
      const res = await axios.post(`https://api.luck8.systems/api/dc-vt`, {
        link: domain,
      });

      if (res.data.success) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  const checkDomainVNPT = async (domain: string) => {
    try {
      const res = await axios.post(`https://api.luck8.systems/api/dc-vnpt`, {
        link: domain,
      });

      if (res.data.success) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  const checkDomainFPT = async (domain: string) => {
    try {
      const res = await axios.post(`https://api.luck8.systems/api/dc-fpt`, {
        link: domain,
      });

      if (res.data.success) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  const checkDomainVT4G = async (domain: string) => {
    try {
      const res = await axios.post(`https://api.luck8.systems/api/4g-vt`, {
        link: domain,
      });

      if (res.data.success) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  const checkDomainVINA4G = async (domain: string) => {
    try {
      const res = await axios.post(`https://api.luck8.systems/api/4g-vina`, {
        link: domain,
      });

      if (res.data.success) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  const checkDomainMOBI4G = async (domain: string) => {
    try {
      const res = await axios.post(`https://api.luck8.systems/api/4g-mobi`, {
        link: domain,
      });

      if (res.data.success) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  return (
    <div className="p-20 w-full bg-[#282c34] min-h-screen">
      <div className="flex justify-center">
        <div className="w-1/2">
          <h1 className="font-bold text-xl text-[#ffffff]">Danh sách Domain</h1>
          <TextArea
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className="mt-4"
            placeholder="Nhập danh sách domain"
            autoSize={{ minRows: 5, maxRows: 10 }}
          />
          <Button className="mt-4" type="primary" onClick={() => handleClickCheckDomain()} disabled={loading} loading={loading} style={{ color: '#ffffff' }}>Nhấn để kiểm tra</Button>
        </div>
      </div>
      <div className="w-full mt-24">
        <h1 className="font-bold text-xl text-[#ffffff]">Domain kiểm tra</h1>
        {loading && (
          <span className="text-[#ffffff]">(Data đang chạy vui lòng chờ trong phút lát)</span>
        )}
        <Table scroll={{ x: 1500 }} className="mt-4" columns={columns} dataSource={data} pagination={false} />
      </div>
    </div>
  );
}

export default Checkblock;